'use client'

import { Tables } from '@/types/supabase-generated'
import bbox from '@turf/bbox'
import type { FeatureCollection } from 'geojson'
import type { CircleLayer } from 'react-map-gl'
import Map, { Layer, Marker, Source, Popup } from 'react-map-gl'
import { ListingMarker } from './ListingMarker'
import { useState } from 'react'
import { Listing } from '@/models/Listing'

import 'mapbox-gl/dist/mapbox-gl.css'

export const ListingMap = ({ data }: { data: Tables<'listings_geojson'>[] }) => {
  const [selectedListing, setSelectedListing] = useState<Listing | null>(null)
  const listings = data.map((l) => new Listing(l))

  const features: FeatureCollection = {
    type: 'FeatureCollection',
    features: listings.map((l) => l.feature),
  }

  const [minLng, minLat, maxLng, maxLat] = bbox(features)

  return (
    <Map
      mapboxAccessToken={process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN}
      initialViewState={{
        bounds: [minLng, minLat, maxLng, maxLat],
        fitBoundsOptions: { padding: 40 },
      }}
      mapStyle='mapbox://styles/mapbox/streets-v12'
    >
      {listings.map((listing) => (
        <ListingMarker key={listing.id} listing={listing} onClick={setSelectedListing} />
      ))}

      {selectedListing && (
        <Popup
          anchor='top'
          longitude={selectedListing.longitude}
          latitude={selectedListing.latitude}
          onClose={() => setSelectedListing(null)}
          focusAfterOpen={true}
          closeButton={false}
        >
          <div>{selectedListing.title}</div>
          <img width='100%' src={selectedListing.primaryImageUrl} />
        </Popup>
      )}
    </Map>
  )
}
