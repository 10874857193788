import { Tables } from '@/types/supabase-generated'
import type { Feature } from 'geojson'
import { Marker, Point } from 'react-map-gl'
import Pin from './Pin'
import { Listing } from '@/models/Listing'

export const ListingMarker = ({ listing, onClick }: { listing: Listing; onClick: (listing: Listing) => void }) => {
  return (
    <Marker
      key={listing.id}
      longitude={listing.longitude}
      latitude={listing.latitude}
      // anchor='bottom'
      onClick={(e: any) => {
        e.originalEvent.stopPropagation()
        onClick(listing)
      }}
    >
      <Pin />
    </Marker>
  )
}
