import { Tables } from '@/types/supabase-generated'
import type { Feature, Point } from 'geojson'

export class Listing {
  readonly feature: Feature<Point>

  constructor(readonly listing: Tables<'listings_geojson'>) {
    this.feature = JSON.parse(listing.feature_geojson ?? '') as Feature<Point>
  }

  get id() {
    return this.listing.id
  }

  get title() {
    return this.listing.title
  }

  get updatedAt(): string {
    return this.listing?.updated_at ?? ''
  }

  get latitude() {
    return this.feature.geometry.coordinates[1]
  }

  get longitude() {
    return this.feature.geometry.coordinates[0]
  }

  get primaryImageUrl() {
    return `https://res.cloudinary.com/lumber-manufactory/image/upload/listings/${this.id}/_primary.webp`
  }
}
